<template lang="pug">
q-tab-panel.panel(:name="name")
  template(v-for="item in data")
    .item(:key="`${field.value} ${item.label}`", v-if="isFieldShown(item)")
      span {{ item.label }}
      template(v-if="item.type === 'floor_plan'")
        img.broken-img(:src="getImageSrc(item.value)")
      template(v-else-if="item.type === 'attachments'")
        template(v-if="item.value.length")
          a(v-for="i in item.value", :key="i.url", :href="i.url", target="_blank") {{ i.file_name }}
        span(v-else) {{ "-" }}
      template(v-else-if="item.type === 'link'")
        template(v-if="item.value")
          a(:href="item.value", target="_blank") {{ item.value }}
        span(v-else) {{ "-" }}
      span(v-else) {{ getFieldValue(item) }}
</template>

<script setup>
import { handleDateRow } from "@/services/reformatStrings";

const props = defineProps({
  /*
  Данные таба

  Пример данных:
  'data': [
    {
      "label": "№",
      "value": 6,
      "type": "string"
    },
    {
      "label": "Название",
      "value": "Офис - Кутузовский",
      "type": "string"
    },
    {
      "label": "Активные заявки",
      "value": 24,
      "measure_unit": "",
      "type": "active_issues"
    },
    {
      "label": "Предпросрок",
      "value": 0,
      "measure_unit": "",
      "type": "pre_overdue_issues"
    },
    {
      "label": "Просроченные заявки",
      "value": 20,
      "measure_unit": "",
      "type": "overdue_issues"
    }
  ]
  */
  data: {
    type: Array,
    required: true,
  },
  /*
  Данные поля

  Пример данных:
  'field':{
    "label": "Строение",
    "value": "building_full_title",
    "extra": {
      "label": "Подробнее",
      "template_id": 17,
      "object_id": 6,
      "path": "extra_object_information"
    }
  }
  */
  field: {
    type: Object,
    required: true,
  },
  /* Имя таба (мета информация). Пример: 'description' */
  name: {
    type: String,
    required: true,
  },
  /* Показатель показа пустых полей */
  showEmpty: {
    type: Boolean,
    required: true,
  },
});

const blankFieldValue = "-";

const isFieldShown = item => {
  return getFieldValue(item) !== "-" || props.showEmpty;
};

const getFieldValue = field => {
  if (field.type === "date" && field.value) return handleDateRow(field, "value");
  else if (field.value === 0 && field.measure_unit) return `${field.value} ${field.measure_unit}`;
  else if (field.value === 0 && !field.measure_unit) return `${field.value}`;
  else if (field.measure_unit) return field.value ? `${field.value} ${field.measure_unit}` : blankFieldValue;
  else return field.value || blankFieldValue;
};

const getImageSrc = value => {
  if (value) {
    return value;
  } else {
    return require("/src/assets/icons/object_pass/image_not_found.svg");
  }
};
</script>

<style lang="scss">
.panel {
  padding: 16px !important;

  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr;

  gap: 16px;

  .item {
    display: flex;
    flex-direction: column;
    color: var(--header-title-color);
    font-size: 14px;
    span,
    a {
      word-break: break-word;
    }
    a {
      color: #5b7aff;
    }
    span:last-of-type {
      font-weight: 600;
    }
    span:first-of-type {
      font-weight: inherit !important;
    }
  }
}
</style>
