<template lang="pug">
div 
  q-item.list__item_wrapper(v-if="!hideSelectAll")
    .list__item
      .item__empty
      checkbox(:label="currentLocales.select_all", :value="selectedAll", @input="changeAllValues")

  //- force-fallback="true" must be set to use library fallback to stop freezes when too many columns 
  //- selected and saved and user tried to reorder columns
  draggable.list-group(
    :value="list",
    force-fallback="true",
    @input="onOrderChange",
    ghost-class="select-fields__ghost",
    handle=".item__handle"
  )
    q-item.list__item_wrapper(v-for="item in list", :key="item.name")
      .list__item
        .item__handle(v-if="allowDrag(item)")
          inline-svg(:src="require('../../../../assets/icons/dynamic-issues/index/move.svg')")
        .item__empty(v-else) 
        checkbox(
          :label="item.label",
          :value="item.value",
          :disable="item.disabled",
          @input="val => changeValue(item, val)"
        )
        .item__toggle(v-if="childrenExist(item)", @click="toggleShowChildren(item)")
          inline-svg.item__toggle__svg(
            v-if="showChildren(item)",
            :src="require('../../../../assets/icons/dynamic-issues/index/chevron-up.svg')"
          )
          inline-svg.item__toggle__svg(
            v-else,
            :src="require('../../../../assets/icons/dynamic-issues/index/chevron-down.svg')"
          ) 
      .item__children(v-if="childrenExist(item)") 
        transition(name="slide-fade")
          select-fields-checkboxes(
            v-if="showChildren(item)",
            :list="item.children",
            :parentItem="item",
            @change="onChildChange($event, item.name)",
            hideSelectAll
          )
</template>

<script setup>
// Renders list of available checkboxes and it's children. Children are rensered using recursiion.
import { ref, computed, onMounted } from "vue";
import { dynamicIssuesLocales } from "@/services/useLocales";
import draggable from "vuedraggable";
import Checkbox from "@/components/shared/general_components/inputs/Checkbox.vue";
import SelectFieldsCheckboxes from "./SelectFieldsCheckboxes.vue";

const props = defineProps({
  /* 
    Список элементов для отображения в списке. Приходят массив объектов
    Пример: `[ {"label": "Название", "name": "name", "value": false, "index": "0", "disabled": false, "children": [] }]`
  */
  list: { type: Array, default: () => [], required: true },
  /* 
    Объект родителя, с котороыми связаны дети. Тип: object или undefined
    Пример: `{"label": "Название", "name": "name", "value": false, "index": "0", "disabled": false, "children": [] }`
  */
  parentItem: { type: Object, default: () => {}, required: false },
  /* 
    Состояние чекбокса в самом вверху списка, показывающий, что выбраны все чекбоксы
  */
  selectedAll: { type: Boolean, default: false, required: false },
  /* 
    Скрыть чекбокс "Выбрать все", используется при рендере детей (для них сейчас не хотим давать выбрать все)
  */
  hideSelectAll: { type: Boolean, default: false, required: false },
});

const emit = defineEmits([
  /* 
    Значение одного чекбокса поменялось либо изменился порядок чекбоксов. В аргументах отправляется массив 
    (обновленный props.list), где либо изменено значение "value" либо порядок, в формате: 
    `[{"label": "Название", "name": "name", "value": false, "index": "0", "disabled": false, "children": [] }, ...]`
  */
  "change",
]);

const showChildrenMap = ref({}); // We can have multiple items in children. We store it in object { item.name => true/false }

const currentLocales = computed(() => dynamicIssuesLocales.value.select_fields);

const childrenExist = item => {
  return item.children.length > 0;
};

const toggleShowChildren = item => {
  const value = !showChildrenMap.value[item.label];
  showChildrenMap.value = { ...showChildrenMap.value, [item.label]: value };
};

const allowDrag = item => {
  return item.value && !item.disabled;
};

const changeValue = (updatedItem, val) => {
  const newList = props.list.map(item => {
    if (item.name == updatedItem.name) {
      // Remove selection for all children when parent checkbox selection is removed.
      // Backend needs parent to be selected if at least 1 child is selected
      const children = val == false ? deselectAllChildren(item.children) : item.children;

      return { ...item, children, value: val };
    } else {
      return item;
    }
  });

  emit("change", newList);
};

// Set all children to false (recursively, support any nesting)
const deselectAllChildren = list => {
  return list.map(item => {
    const children = item.list || []; // Fallback is added just in case

    if (children.length > 0) {
      return { ...item, children: deselectAllChildren(children) };
    } else {
      return { ...item, children: [], value: false };
    }
  });
};

const onChildChange = (newChildrenList, parentItemName) => {
  const newList = props.list.map(item => {
    if (item.name == parentItemName) {
      const atLeastOneChildrSelected = newChildrenList.some(child => child.value === true);
      let updatedValue;

      // Backend requires parent to be selected it order to render children.
      // That is why we ensure parent will be selected if at least 1 child is selected
      if (atLeastOneChildrSelected) {
        updatedValue = true;
      } else {
        updatedValue = item.value;
      }

      return { ...item, children: newChildrenList, value: updatedValue };
    } else {
      return item;
    }
  });

  emit("change", newList);
};

const onOrderChange = newList => {
  emit("change", newList);
};

const changeAllValues = val => {
  const newList = mapNestedAllValues(props.list, val);
  emit("change-all", { selectedAll: val, newList: newList });
};

// true - only update parents (we don't want to select all children as it result in too many columns)
// false - recursively set all parenents and children to false (child cannot be true when parent is false due backend requirements)
const mapNestedAllValues = (list, val) => {
  return list.map(item => {
    const value = item.disabled ? item.value : val; // disabled value is not touched
    const children =
      item.children && item.children.length && val === false > 0
        ? mapNestedAllValues(item.children, val)
        : item.children;

    return { ...item, value, children };
  });
};

// Allow to show if have at least 1 child selected
const setShowChildrenMap = () => {
  const newMap = Object.fromEntries(
    props.list.map(item => [item.label, item.children.some(childItem => childItem.value)]),
  );
  showChildrenMap.value = newMap;
};

const showChildren = item => {
  return showChildrenMap.value[item.label];
};

onMounted(() => {
  setShowChildrenMap();
});
</script>

<style scoped lang="scss">
$handle-size: 20px;

// Animate children appearance
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

// Draggable class
.select-fields__ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list__item_wrapper {
  padding: 0;
  flex-direction: column; // display children under parent checkbox

  .list__item {
    display: flex;
    align-items: center;
    user-select: none;

    // Quasar label of checkbox.vue component
    :deep(.q-checkbox__label) {
      &::first-letter {
        text-transform: capitalize;
      }
    }

    .item__empty {
      width: $handle-size;
      height: $handle-size;
    }

    .item__handle {
      cursor: move;
      width: $handle-size;
      height: $handle-size;
    }

    .item__toggle {
      cursor: pointer;

      .item__toggle__svg {
        display: block;
      }
    }
  }

  .item__children {
    padding-left: 30px;
  }
}
</style>
