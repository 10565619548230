<template lang="pug">
.bar 
  .bar__loader(v-if="loading")
    q-spinner(color="primary", size="3em")
  .bar__content(v-else)
    .bar__header
      .bar__label {{ values?.legend?.title }}
      .bar__legend 
        .bar__legend__item(v-for="item in values?.legend?.items", :key="item.title")
          .bar__legend__item__circle(:style="`background-color: ${item.color}`")
          .bar__legend__item__label {{ item.title }}

    apexchart(v-if="isSeriesNonempty", type="bar", :height="300", :options="chartOptions", :series="series")
    .apexchart-empty-value(v-else)
      span {{ gridLocales?.hidden_message }}
</template>

<script setup>
import { backend } from "@/api";
import { ref, computed, onBeforeMount, onBeforeUnmount } from "vue";

import { useEmitter } from "@/services/useEmitter";
import { generateFiltersParams } from "@/services/generateFiltersParams";
import { useStore } from "@/store";
import { handleError } from "@/services/handleErrors";
import { currentLocale } from "@/services/useLocales";
import { gridLocales } from "@/services/useLocales";

import i18n from "@/plugins/vue-i18n";

const emitter = useEmitter();

const store = useStore();

const props = defineProps({
  type: { type: String, required: true },
});

const loading = ref(true);

const chartOptions = ref({
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
    events: {
      mouseMove: (e, chartContext, config) => {
        if (!loading.value) {
          const xAxis = chartContext.el.querySelector(".apexcharts-xaxis-texts-g");
          const tooltip = chartContext.el.querySelector(".apexcharts-tooltip");
          const barIndex = config.dataPointIndex;
          const seriesIndex = config.seriesIndex;
          const resetXAxis = () => {
            xAxis.childNodes.forEach(item => {
              item.style.fill = "var(--header-subtitle-color)";
            });
          };
          if (tooltip) {
            tooltip.style.visibility = "visible";
            if (config.dataPointIndex === -1 || config.dataPointIndex === series.value[0].data.length) {
              tooltip.style.visibility = "hidden";
              resetXAxis();
              return;
            }
          }
          const currentBar = chartContext.el.querySelector(`.apexcharts-series[data\\:\\realIndex="${seriesIndex}"]`)
            .childNodes[barIndex];
          const barWidth = Number(currentBar.getAttribute("barWidth"));
          const chartOffsetX = chartContext.el.querySelector(".apexcharts-graphical").getBoundingClientRect().left;
          const chartWrapperMargin =
            chartOffsetX - chartContext.el.querySelector(".apexcharts-canvas").getBoundingClientRect().left;
          const barMarginLeft =
            currentBar.getBoundingClientRect().left -
            chartContext.el.querySelector(".apexcharts-graphical").getBoundingClientRect().left;

          tooltip.style.marginTop = `${currentBar.getAttribute("cy")}px`;
          tooltip.style.marginLeft = `${chartWrapperMargin + barMarginLeft + barWidth / 2}px`;
          if (window.innerWidth - currentBar.getBoundingClientRect().left < 140) {
            tooltip.style.transform = `translate(calc(-100% + ${barWidth}px), -80%)`;
            tooltip.style.setProperty("--arrow-margin", `${188 - barWidth}px`);
            tooltip.style.setProperty("--border-radius", "12px 12px 0px 12px");
          } else {
            tooltip.style.setProperty("--arrow-margin", `50%`);
            tooltip.style.transform = `translate(-50%, -80%)`;
            tooltip.style.setProperty("--border-radius", "12px");
          }
          resetXAxis();
          if (e.x > chartOffsetX) {
            xAxis.childNodes[barIndex].style.fill = "#0EC262";
            tooltip.style.visibility = "visible";
          } else {
            tooltip.style.visibility = "hidden";
          }
        }
      },
      mouseLeave: (e, chartContext) => {
        if (!loading.value) {
          const xAxis = chartContext.el.querySelector(".apexcharts-xaxis-texts-g");
          xAxis.childNodes.forEach(item => {
            item.style.fill = "var(--header-subtitle-color)";
          });
        }
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    colors: ["transparent"],
  },
  colors: [],
  states: {
    hover: {
      filter: { type: "none" },
    },
    active: {
      filter: { type: "none" },
    },
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
  },
  tooltip: {
    followCursor: false,
    fixed: {
      enabled: true,
      position: "topLeft",
    },
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const date = w.config.xaxis.categories[dataPointIndex];
      const color = w.config.colors[seriesIndex];
      let value = series[seriesIndex][dataPointIndex];
      if (props.type === "dynamic_expenses_bar_chart") value += " ₽";

      let text = `<div class='column-chart__tooltip'><div class='column-chart__tooltip__header'><span class='column-chart__tooltip__circle', style='background-color: ${color}'></span>`;
      text += `<span class='column-chart__tooltip__label'>${value}</span></div>`;
      text += `<span class='column-chart__tooltip__value'>${date}</span></div>`;

      return text;
    },
  },
});

const series = ref([]);
const fullSeries = ref([]);
const values = ref([]);

const isSeriesNonempty = computed(() => series.value.some(item => item.data.length > 0));

const generateParams = () => {
  let localQuery = "";
  let localFilters = {};

  let localGrid = store.state.grid["expensesDashboard"];

  if (localGrid) {
    localQuery = localGrid.query || "";
    localFilters = localGrid.filters ? generateFiltersParams(localGrid.filters) : {};
  }

  const params = {
    query: localQuery,
    filters: localFilters,
    type: props.type,
  };

  return params;
};

const getData = async () => {
  const params = generateParams();
  loading.value = true;

  try {
    const { data } = await backend.index("api/v3/expenses_dashboard/charts", { params }, { encodeNestedData: true });

    chartOptions.value.xaxis.categories = data.categories.map(el => getMonth(new Date(el).getMonth()));
    const chartItemsLength = data.categories.length;
    if (window.innerWidth > 1590) {
      chartOptions.value.plotOptions.bar.columnWidth = `${chartItemsLength * 6}px`;
    } else {
      chartOptions.value.plotOptions.bar.columnWidth = `${chartItemsLength * 7}px`;
    }
    chartOptions.value.colors = data.legend.items.map(item => {
      return item.color;
    });
    values.value = data;
    series.value = [];
    fullSeries.value = [];
    data.data.forEach(item => {
      fullSeries.value.push(item);
      series.value.push(item);
    });
  } catch (e) {
    handleError(e);
  } finally {
    loading.value = false;
  }
};

const getMonth = month => {
  return i18n["messages"][currentLocale.value]["date"]["monthsShort"][month];
};

onBeforeMount(async () => {
  await getData();

  emitter.on("refresh-expenses-dashboard-data", getData);
});

onBeforeUnmount(() => {
  emitter.off("refresh-expenses-dashboard-data");
});
</script>

<style lang="scss">
.bar {
  width: 50%;

  background-color: var(--expenses-content-background-color);

  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .apexchart-empty-value {
    min-height: 315px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__legend {
    display: flex;
    flex-direction: row;

    &__item {
      display: flex;
      align-items: center;
      gap: 5px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &__circle {
        width: 8px;
        height: 8px;

        border-radius: 50%;

        background-color: #5b7aff;
      }
      &__label {
        font-weight: 40;
        font-size: 14px;
        color: #8a9099;
      }
    }
  }

  &__label {
    color: var(--expenses-main-text-color);
    font-size: 20px;
  }
  & .apexcharts-series path {
    clip-path: inset(0 0 0 0 round 15px 15px 0 0);
  }
  & .apexcharts-tooltip {
    border: 0.5px solid grey !important;
    background: var(--expenses-dashboard-custom-back) !important;
    color: var(--expenses-main-text-color) !important;
  }
  & .apexcharts-tooltip-title {
    background: transparent !important;
    border-bottom: 0.5px solid grey !important;
  }
  & .apexcharts-xaxis-label,
  & .apexcharts-yaxis-label {
    fill: var(--header-subtitle-color);
  }

  &__loader {
    min-height: 377px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .apexcharts-tooltip {
    box-shadow: 0px 0px 24px 0px #2c3f581f !important;
    border: none !important;
    --arrow-margin: 50%;
    --border-radius: 12px;
    overflow: visible;

    &::after {
      content: "";
      position: absolute;
      border: 11px solid transparent;
      border-top: 11px solid var(--quantity-card-background);
      bottom: -22px;
      left: var(--arrow-margin);
      transform: translateX(-50%);
    }
    .column-chart__tooltip {
      padding: 8px 20px;
      background-color: var(--quantity-card-background);
      width: 188px;
      height: 60px;
      text-align: center;
      border-radius: var(--border-radius) !important;

      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }

      &__circle {
        width: 8px;
        height: 8px;

        border-radius: 50%;
      }

      &__label {
        font-weight: 400;
        font-size: 14px;
        color: #8a9099;
      }
      &__value {
        font-weight: 400;
        font-size: 14px;
        color: #78839c;
      }
    }
  }
}
</style>
