<template lang="pug">
div
  q-card.notification-rules-panel.no-shadow
    q-card-section
      .row(:class="{ 'notification-rules-panel-sticky': dataLoaded }")
        .col-6.col-md-2.rules-panel-header.plug(v-if="!dataLoaded")
          span {{ notification_rule_locales.choose_facitity_and_service }}
        .col-12.col-md-2.rules-panel-header(v-else)
          span {{ notification_rule_locales.role }}
        .col-12.col-md-1.rules-panel-header(v-for="ev in data.events", :key="ev.label + ev.event")
          span {{ ev.label }}
      .row(v-for="role in data.roles", :key="role['id']", v-if="!loading")
        .rules-panel-role-column.col-12.col-md-2
          span {{ role["title"] }}

        .rules-panel-checkboxes.col-12.col-md-1(v-for="event in data.events", :key="event.label + event.event")
          check-boxes(
            :parentData="{ grid: grid, data: getNeedData(role['id'], event), types: data.types, role: role['id'], event: event.event, statusId: event.status_id, serviceId: service_id, path: path }"
          )
</template>

<script>
import CheckBoxes from "./CheckBoxes";

export default {
  components: {
    CheckBoxes,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      data: [],

      loading: true,
      dataLoaded: false,
    };
  },

  computed: {
    facility_id() {
      return this.currentFilters["facility_id"];
    },
    service_id() {
      return this.currentFilters["service_id"];
    },

    notification_rule_locales() {
      return this.locales.notification_rule[this.current_locale];
    },
  },

  watch: {
    facility_id(newValue, oldValue) {
      if (newValue) {
        let params = {
          facility_id: newValue.value,
          service_id: null,
        };
      } else if (oldValue) {
        let attr = {
          grid_name: this.grid,
          facility_id: oldValue.value,
        };
        this.data = [];
        this.dataLoaded = false;
      }
    },

    service_id(newValue, oldValue) {
      if (this.facility_id && newValue) {
        let params = {
          facility_id: this.facility_id.value,
          service_id: newValue ? newValue.value : newValue,
        };
        setTimeout(() => {
          this.loadGridData(params);
        }, 300);
      } else {
        this.data = [];
        this.dataLoaded = false;
      }
    },
  },

  methods: {
    reloadData() {
      this.loading = false;
      if (this.facility_id && this.service_id) {
        const params = {
          facility_id: this.facility_id.value,
          service_id: this.service_id.value,
        };
        this.loadGridData(params);
      }
    },

    async loadGridData(params = {}) {
      this.loading = true;
      try {
        const res = await this.$backend.index(this.path, { params: params });
        this.data = res.data;
        this.loading = false;
        this.dataLoaded = true;
      } catch (error) {
        await handleError(error);
      }
    },

    getNeedData(role, event) {
      return this.data.data.find(item => {
        return item.status_id === event.status_id && item.role === role && item.event === event.event;
      });
    },
  },
};
</script>

<style lang="scss"></style>
